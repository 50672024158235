import * as React from "react";

export class Config extends React.Component<any, any> {
  render() {
    return (
      <div className="container">
        <div className="jumbotron text-center">
          <h1 className="display-3">Configure</h1>
          <p>Coming soon.</p>
        </div>
      </div>
    );
  }
}
